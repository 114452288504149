import { type ReactNode } from 'react'

export const Badge = ({
  children,
  invisible = false,
  variant = 'standard',
  badgeContent,
  badgeContainerClassName = '',
  className,
}: {
  children: ReactNode
  invisible?: boolean
  variant?: 'dot' | 'standard' | 'large'
  badgeContent?: ReactNode
  badgeContainerClassName?: string
  className?: string
}) => {
  return (
    <div className={`relative max-w-fit ${badgeContainerClassName}`}>
      <div
        className={`${invisible ? 'invisible' : ''} -right-2 -top-1 flex ${
          variant === 'dot'
            ? 'size-2'
            : variant === 'large'
              ? 'size-5'
              : 'size-4'
        } absolute items-center justify-center rounded-full bg-primary text-xs font-medium text-white ${className ?? ''}`}
      >
        {badgeContent}
      </div>
      {children}
    </div>
  )
}
