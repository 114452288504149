/* eslint-disable promise/always-return */
'use client'

import { Badge } from '../library/badge'
import { ButtonIcon } from '@/components/elements/button-icon/button-icon'
import { routeCalculatorRelative } from '@/lib/route-calculator'
import { useCart } from '../cart/cart-context'
import { useLogin } from '../account/context/use-login'
import { useRouter } from 'next/navigation'

export const WishListButton = () => {
  const { loggedIn, openLogin } = useLogin()
  const router = useRouter()

  const WishListClick = () => {
    if (loggedIn) {
      router.push(routeCalculatorRelative.wishlist)
      router.refresh()
    } else {
      openLogin()
    }
  }

  return (
    <ButtonIcon
      color='light'
      iconVariant='wishlist-misc'
      onClick={WishListClick}
      className='cursor-pointer'
      title='Go to wishlist'
    />
  )
}

export const NavBarCart = () => {
  const { cartCount } = useCart()
  const router = useRouter()

  return (
    <Badge
      badgeContent={(cartCount ?? 0) > 0 ? cartCount : undefined}
      className='right-0 top-0 m-0 !bg-accent2 !text-primary-dark'
      invisible={(cartCount ?? 0) === 0}
      variant='standard'
    >
      <ButtonIcon
        color='light'
        iconVariant='shopping-bag-hand-bag-2-ui-line-pro'
        onClick={() => {
          router.push(routeCalculatorRelative.cart)
          router.refresh()
        }}
        title='Go to cart'
      />
    </Badge>
  )
}
