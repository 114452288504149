import { type ReactNode } from 'react'
import { ButtonIcon } from '../elements/button-icon/button-icon'
import { Icon, type IconName } from '@/icons/icon'
import { type CategoryData } from './category-data'
import Link from 'next/link'

const MobileListItem = ({
  children,
  onClick,
  className,
}: {
  children: ReactNode
  onClick?: () => void
  className?: string
}) => (
  <li
    className={`flex h-16 content-center justify-between border-b pl-6 text-sm font-semibold text-black hover:bg-secondary-light hover:pl-9 ${className}`}
    role='button'
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      onClick?.()
    }}
  >
    {children}
  </li>
)

const MobileListLink = ({
  children,
  onClick,
  url,
  className,
}: {
  children: ReactNode
  onClick?: () => void
  url: string
  className?: string
}) => (
  <li onClick={onClick}>
    <Link
      prefetch={false}
      className={`flex h-16 content-center justify-between border-b pl-6 text-sm font-semibold text-black hover:bg-secondary-light hover:pl-9 sm:h-8 sm:w-[132px] sm:border-0 ${className}`}
      href={url}
      role='navigation'
    >
      {children}
    </Link>
  </li>
)

export const MobileMenuItemWithIcon = ({
  text,
  iconName,
  onClick,
  bold = false,
}: {
  text: string
  iconName: IconName
  onClick?: () => void
  bold?: boolean
}) => (
  <MobileListItem onClick={onClick} className='sm:hidden'>
    <div className='my-auto flex justify-start'>
      <Icon name={iconName} color='dark' />
      <span className={`${bold && 'font-bold'} pl-3`}>{text}</span>
    </div>
  </MobileListItem>
)

export const MobileMenuCategoryItem = ({
  categoryData,
  onClick,
}: {
  categoryData: CategoryData
  onClick: () => void
}) => (
  <MobileListLink url={categoryData.url ?? '/'} onClick={onClick}>
    <span className='my-auto pl-8 font-medium'>{categoryData.name}</span>
  </MobileListLink>
)

interface MobileMenuLinkProps {
  url?: string
  text: string
  onClick: () => void
}

export const MobileMenuLink = ({
  url,
  text,
  onClick,
}: {
  url?: string
  text: string
  onClick: () => void
}) => (
  <MobileListLink url={url ?? '/'} onClick={onClick}>
    <span className='my-auto'>{text}</span>
  </MobileListLink>
)

interface MobileMenuLinkExpandActionProps extends MobileMenuLinkProps {
  expandAction: () => void
}

export const MobileMenuLinkExpandAction = ({
  url,
  text,
  onClick,
  expandAction,
}: MobileMenuLinkExpandActionProps) => (
  <MobileListLink
    className='sm:border-r-4 sm:border-solid sm:border-r-secondary'
    url={url ?? '/'}
    onClick={onClick}
  >
    <span className='my-auto'>{text}</span>
    <div
      role='button'
      className='flex h-full border-l-1 px-8 sm:hidden'
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        expandAction()
      }}
    >
      <ButtonIcon
        className='my-auto'
        color='dark'
        iconVariant='arrow-right-1-regular'
        iconSize='s'
      />
    </div>
  </MobileListLink>
)
