import { ButtonIcon } from '@/components/elements/button-icon/button-icon'
import { Icon } from '@/icons/icon'

interface MenuButtonProps {
  showMenu: boolean
  text: string
  onClick?: () => void
}

export const MenuButton = ({ showMenu, text, onClick }: MenuButtonProps) => (
  <>
    <div className='fixed right-4 top-4 sm:hidden'>
      <ButtonIcon
        color='light'
        onClick={onClick}
        iconVariant='navigation-menu-regular'
        title='Shop Menu'
      />
    </div>
    <button
      className='m-auto hidden h-10 border-none bg-none sm:flex'
      onClick={onClick}
    >
      <span
        className='m-auto text-lg font-bold leading-6 text-white'
        data-testid='buttonText'
      >
        {text}
      </span>
      <div className='m-auto flex pl-2'>
        <Icon
          size='s'
          color='secondary'
          name='arrow-up-1-regular'
          testID='icon-arrow-top'
          className={`transform-gpu transition-transform ${showMenu ? '' : 'rotate-180'}`}
        />
      </div>
    </button>
  </>
)
