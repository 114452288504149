'use client'

import { Button } from '@/components/library/button'
import Link from 'next/link'
import { routeCalculatorRelative } from '@/lib/route-calculator'
import { useLogin } from './context/use-login'

export const LoginButton = () => {
  const { userAttributes, openLogin } = useLogin()

  if (userAttributes != null) {
    const initials = `${userAttributes.given_name?.charAt(0)}${userAttributes.family_name?.charAt(0)}`

    return (
      <Link
        prefetch={false}
        href={routeCalculatorRelative.account}
        data-testid='user-signed-in'
      >
        <Button
          variant='outlined'
          size='medium'
          style={{
            width: 40,
            minWidth: 40,
          }}
          className='focused:bg-dark focused:text-white w-10 min-w-[40px] border-secondary text-secondary-light hover:bg-white  hover:text-dark active:bg-dark active:text-white'
        >
          {initials.toUpperCase()}
        </Button>
      </Link>
    )
  }
  return (
    <>
      <Button
        variant='outlined'
        size='medium'
        data-testid='sign-in-button'
        onClick={() => openLogin('splash')}
        className='focused:bg-dark focused:text-white border-secondary text-white hover:bg-white hover:text-dark active:bg-dark active:text-white'
      >
        Sign In
      </Button>
    </>
  )
}
