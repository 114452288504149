const cleanKey = (key: string) => key.replace(/^(\*\*\/)?:+/, '')

const cleanSlashDangles = (value: string) => value.replace(/^\/+|\/+$/, '')

function matchLast(pattern: string, path: string) {
  const key = cleanKey(pattern)
  const match = path.match(/\/(?:.(?!\/))+$/)

  if (match === null) {
    return {}
  }

  return {
    [key]: cleanSlashDangles(match[0]),
  }
}

function matchEach(pattern: string, path: string) {
  const patternParts = pattern.split('/')
  const pathParts = path.split('/')
  const match: Record<string, string | null | undefined> = {}

  for (const [index, current] of patternParts.entries()) {
    if (!current.startsWith(':')) {
      continue
    }

    const key = cleanKey(current)
    const value = pathParts[index]

    if (value !== undefined) {
      match[key] = value
    }
  }

  return match
}

export default function matchPath(_pattern: string, _path: string) {
  const pattern = cleanSlashDangles(_pattern)
  const path = cleanSlashDangles(_path)

  if (pattern === '' || path === '') {
    return {}
  }

  if (pattern.includes('**/')) {
    if (pattern.startsWith('**/')) {
      return matchLast(pattern, path)
    }

    const [prefix, rest] = pattern.split('**')
    const cleanPrefix = cleanSlashDangles(prefix)

    if (path.startsWith(cleanPrefix)) {
      return matchLast(`**/${cleanSlashDangles(rest)}`, path)
    }

    return {}
  }

  return matchEach(pattern, path)
}
