'use client'

import {
  useEffect,
  useState,
  type ReactNode,
  Suspense,
  use,
  useTransition,
} from 'react'

import { routeCalculatorRelative } from '@/lib/route-calculator'
import Link from 'next/link'
import { MenuButton } from './menu-button/menu-button'

import { Icon } from '@/icons/icon'
import { getPostcodeClient } from '@/lib/data/postcode-client'
import { useRouter } from 'next/navigation'
import { useLogin } from '../account/context/use-login'
import { ButtonIcon } from '../elements/button-icon/button-icon'
import { ResolvedImage } from '../utility/image'
import { type CategoryData } from './category-data'
import {
  MobileMenuItemWithIcon,
  MobileMenuLink,
  MobileMenuLinkExpandAction,
} from './mobile-menu-components'
import { isNullOrEmpty } from '@/utils/string-extensions'

const PostcodeText = ({ children }: { children: ReactNode }) => (
  <span className='m-auto pl-1 text-sm font-bold leading-5 text-white'>
    {children}
  </span>
)

const CategoryMenuItems = ({
  categoriesData,
}: {
  categoriesData: CategoryData[]
}) => {
  return (
    <ul>
      {categoriesData?.map((item, index) => {
        return (
          <li
            key={index}
            className='p-0'
            data-testid='secondary-menu-list-item'
          >
            <Link
              prefetch={false}
              href={item.url ?? '/'}
              className='flex w-full px-3 py-2 text-sm hover:bg-secondary-light hover:pl-4'
            >
              <ResolvedImage
                width={14}
                height={14}
                className='mr-[6px]'
                src={item?.iconUrl ?? ''}
                alt={item?.name}
              />
              {item?.name}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

interface MenuProps {
  showMenu: boolean
  setShowMenu: (showMenu: boolean) => void
  categoriesData: CategoryData[]
}

const MobileMenuHeader = ({
  text,
  onClose,
}: {
  text: string
  onClose: () => void
}) => (
  <div className='flex h-24 w-screen items-center justify-between border-b-4 border-primary bg-primary-dark px-6 sm:hidden'>
    <span className='text-xl font-bold text-white'>{text}</span>
    <ButtonIcon
      iconVariant='close-regular'
      color='light'
      iconSize='xs'
      onClick={onClose}
    />
  </div>
)

const MobileMenu = ({
  showMenu,
  setShowMenu,
  postcode,
  categoriesData,
}: MenuProps & {
  postcode: string
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isPending, startTransaction] = useTransition()
  const [activeMenu, setActiveMenu] = useState('Main')
  const { loggedIn, openLogin, setShowPostcode } = useLogin()
  const router = useRouter()

  // Runs each time the menu is opened
  useEffect(() => {
    setActiveMenu('Main')
  }, [router, showMenu])

  useEffect(() => {
    if (!showMenu && isNullOrEmpty(document.body.style.overflow)) return
    document.body.style.overflow = showMenu ? 'hidden' : 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [showMenu])

  const WishListClick = () => {
    setShowMenu(false)
    if (loggedIn) {
      router.push(routeCalculatorRelative.wishlist)
    } else {
      openLogin()
    }
  }

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          startTransaction(() => {
            setShowMenu(false)
          })
        }}
        className={`fixed inset-0 cursor-pointer ${!showMenu && 'hidden'}`}
      />
      <div
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()

          startTransaction(() => {
            setShowMenu(false)
          })
        }}
        itemType='https://schema.org/SiteNavigationElement'
        className={`fixed inset-0 z-[1200] transform-gpu cursor-pointer transition-all sm:top-[90px]  sm:h-[calc(100vh-90px)] ${showMenu ? '' : 'hidden sm:block sm:-translate-x-full'}`}
      >
        <MobileMenuHeader
          text={activeMenu === 'Main' ? 'Menu' : 'Categories'}
          onClose={() => {
            setShowMenu(false)
          }}
        />
        <div
          className={` grid h-screen w-[200vw] cursor-default grid-cols-2 bg-white  transition sm:w-[424px] sm:shadow-2xl lg:w-[509px] ${activeMenu === 'Main' ? '' : '-translate-x-1/2'}`}
          role='presentation'
        >
          <div>
            <ul className='sm:hidden'>
              <MobileMenuItemWithIcon
                text='Wishlist'
                iconName='wishlist-misc'
                onClick={WishListClick}
              />
              <MobileMenuItemWithIcon
                text={postcode}
                iconName='pin-regular'
                onClick={() => {
                  startTransaction(() => {
                    setShowPostcode(true)
                  })
                }}
              />
            </ul>
            <div className='mt-4 flex h-16 border-b pl-6 text-xl font-bold sm:hidden'>
              <span className='my-auto'>Shop</span>
            </div>
            <div className='h-full sm:border-0 sm:border-r-2'>
              <ul className='sm:flex sm:flex-col sm:items-end sm:pt-5 '>
                <MobileMenuLinkExpandAction
                  text='Categories'
                  url={routeCalculatorRelative.category}
                  onClick={() => {
                    setShowMenu(false)
                  }}
                  expandAction={() => {
                    setActiveMenu('Categories')
                  }}
                />
                <MobileMenuLink
                  url={routeCalculatorRelative.catalogues}
                  text='Catalogues'
                  onClick={() => {
                    setShowMenu(false)
                  }}
                />
                <MobileMenuLink
                  url={routeCalculatorRelative.retailers}
                  text='Retailers'
                  onClick={() => {
                    setShowMenu(false)
                  }}
                />
              </ul>
            </div>
          </div>
          <div className='h-full overflow-auto sm:pt-5 '>
            <MobileMenuItemWithIcon
              onClick={() => {
                startTransaction(() => {
                  setActiveMenu('Main')
                })
              }}
              bold={true}
              text='Back to menu'
              iconName='arrow-left-1-regular'
            />
            <CategoryMenuItems categoriesData={categoriesData} />
          </div>
        </div>
      </div>
    </>
  )
}

export const ShopMenu = ({
  categoriesData,
  postcodeServer,
  setShowMenu,
  showMenu,
}: {
  categoriesData: Promise<CategoryData[]>
  postcodeServer?: string
  setShowMenu: (showMenu: boolean) => void
  showMenu: boolean
}) => {
  const resolvedCategoryData = use(categoriesData)
  const postcode = postcodeServer ?? getPostcodeClient()

  return (
    <MobileMenu
      showMenu={showMenu}
      setShowMenu={setShowMenu}
      postcode={postcode}
      categoriesData={resolvedCategoryData}
    />
  )
}

export const ShopMenuContainer = ({
  categoriesData,
  postcode,
}: {
  categoriesData: Promise<CategoryData[]>
  postcode?: string
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isPending, startTransaction] = useTransition()
  const [showMenu, setShowMenu] = useState(false)
  const { setShowPostcode } = useLogin()
  return (
    <>
      <div>
        <MenuButton
          onClick={() => {
            startTransaction(() => {
              setShowMenu(!showMenu)
            })
          }}
          showMenu={showMenu}
          text={'Shop'}
        />
        <Suspense fallback={null}>
          <ShopMenu
            categoriesData={categoriesData}
            postcodeServer={postcode}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
          />
        </Suspense>
      </div>
      <div
        onClick={() => {
          setShowPostcode(true)
        }}
        className='ml-2 hidden h-10 min-w-[54px] max-w-[62px] cursor-pointer px-1 py-0 sm:flex md:ml-4'
      >
        <div className='m-auto flex'>
          <Icon size='s' color='light' name='pin-regular' />
        </div>
        <PostcodeText>{postcode}</PostcodeText>
      </div>
    </>
  )
}
